<template>
  <div class="home-container">
	<div  class="linear-bg">
		<div  style="display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 0px 20px;">
			<div data-v-f99d06fa="" style="text-align: center;">
		
					<font style="vertical-align: inherit;"></font>
			</div>
		</div>		
		<div style="width:300px;" @click="showPopup"> 
			<i  class="van-icon van-icon-location-o"></i>
			<font style="vertical-align: inherit;">{{$t('gbqy')}}</font>
		</div>		
	</div>
	  
	  
   
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper"  style="background: linear-gradient(270deg, #e6c3a1, #7e5678);" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
	  
      <div class="notice-bar" style="background: #b47d9c;">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#b47d9c"
			
			
            color="white"
            :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div>
	  
      <div class="hot-game">
<!--        <div class="hot-title-div">
          <div>
            <span>{{$t('index.task')}}</span></div>
          <div @click="gotoMenu('/Game')">
            <span>{{$t('index.more')}}</span>
            <van-icon name="arrow" color="#2b333f" />
          </div>
        </div> -->
		
        <div class="hot-items-div">
          <van-grid :border = false :column-num="4" :icon-size="20">
            <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{v.name}}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
	  
	  
	  
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="hot-recommend">
     <!--     <div class="hot-title-div">
            <div>
              <span>{{$t('index.hot')}}</span></div>
            <div>
              <span @click="gotoMenu('/Video')">{{$t('index.more')}}</span>
              <van-icon name="arrow" color="#2b333f" />
            </div>
          </div> -->
          <div class="movie_list_0">
           <!-- <swiper class="movie_swiper" :options="movielistSwiperOption" 
			>
              <swiper-slide v-for="(v,key) in movielist_0" :key="key" >
                <van-image class="movie_cover" @click="toPlayVideo(v.id)"  round :src="v.cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper> -->
			
			<div class="xiaofei">
         <!--   <swiper class="movie_swiper"   :options="movielistSwiperOption2" 
			>
              <swiper-slide v-for="(v,key) in movielist_2" :key="key" >
                <van-image class="movie_cover1"   @click="toXiaoFei(v.id)"  round :src="v.img_url">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper> -->			
			</div>
			
          </div>
		  
		  
<!--          <div class="hot-title-div">
            <div>
              <span>{{$t('index.more')}}</span></div>
            <div @click="gotoMenu('/Video')">
              <span>{{$t('index.recmonmand')}}</span>
              <van-icon name="arrow" size="25" color="#2b333f" />
            </div>
          </div> -->
		  
		  <div  class="noticeContent">
			  
			<div  class="sm">
				<div>
					
					<div  class="left">
						<p> {{index.index_title1}}</p>
						<p  style="text-align: center;">{{index.index_title2}}</p>
						<p  style="text-align: center;">{{index.index_title3}}</p>
					</div>			
					<div  class="right">
						<p >{{index.index_conent}}</p>
					
					</div>			
				</div>
				<span style="color: white;">
					{{index.index_foot}}
				</span>
				
				
				
			</div>
			<br />
			
			<div class="navs">
				<nav >
					<a :class="{active:mt}"   @click="tapp(1);">{{$t('xsjmtzy')}}</a>
					<a :class="{active:tk}"   @click="tapp(2);">{{$t('fwtk')}}</a>
				</nav>				
				
				
			</div>	
			<br />
			<div  class="item" v-show="mt">
				<ul>
					<li v-for="(v,key) in movielist_2" :key="key"  @click="profile(v.id)">
					<!-- <i >{{v.tag.name}}</i> -->
					<i>{{$t('yzwc')}}</i>
					<div  class="left">
						<p>ID:{{v.bianma}}</p>
						<div  class="ziliao">
						
							<span v-for="(vv,kk) in v.xuanfei_name" :key="kk">{{vv}}</span>
							
						<!-- 	<span>{{$t('nicename')}} ：{{v.nicename}}</span>
							<span>{{$t('type_name')}} ：{{v.type_name}}</span>
							
							
							<span>{{$t('diqu')}} ：{{v.xuanfei_address_name}}</span>
							<span>{{$t('fwxm2')}}：{{$t('jihuohokechakan')}}</span> -->
						</div>
						<div  class="ft">
							<img  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII=" alt="">
								<span >{{v.view}}</span>
								<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9T6krwnLUmlVkDSv9UVMDhEpTblBQ6uZ54ZAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg==" alt="">
								<span >{{v.likes}}</span>
						</div>
					</div>
					<div class="right">
						<div  class="avatar">
							<img :src="v.img_url" alt="">
						</div>						
					</div>						
					</li>					

				</ul>				
				
				
			</div>					
			<div v-show="tk">
				<div  class="item tiaokuan">
					<ul >
						<li>
							<div >
								<p >{{$t('ptzy')}}</p>
								<span >{{$t('ptdy')}}</span>
							</div>							
							<div >
								<p >{{$t('fwfw')}}</p>
								<span>{{$t('tchy')}}</span>
							</div>							
						</li>
					</ul>
				</div>
			</div>		
	
		  </div>		  	
		  
          <!-- <div class="movie_list_1"> -->
            <!-- <div class="movie-list-item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)"> -->
            <!--  <van-image class="cover_img"  round :src="v.cover">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image> -->
             <!-- <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{v.title}}</span>
                  <span>{{$t('video.play')}}:{{v.count}}</span>
                </div>
              </div> -->
            <!-- </div> -->
            <!-- <div class="hot-recommend-more" @click="gotoMenu('/Video')">{{$t('index.more')}}</div> -->
          <!-- </div> -->
        </div>
      </van-pull-refresh>
    </div>
	
	<van-popup v-model="show" >
		<ul class="cityPopup">
			<li><i class="cubeic-like"></i> {{$t('rmdq')}}</li>
			<li  v-for="(val, key) in addlist" :key="key" @click="addlist_id(val.id);">
				<font style="vertical-align: inherit;">{{val.name}}</font>
			</li>
			<li ><span >{{$t('qtdq')}}</span></li>

		</ul>
	</van-popup>	
	
	<van-popup v-model="show2" >
		<ul class="cityPopup">
			<li><i class="cubeic-like"></i> {{$t('rmdq')}}</li>
			<li  v-for="(val, key) in x_list" :key="key" @click="addlist_id2();">
				<font style="vertical-align: inherit;">{{val.name1[0]}}  
				<font style="vertical-align: inherit;" v-show="val.name1[1]">{{val.name1[1]}}   </font> </font>

			</li>
		</ul>
	</van-popup>	
	
	
  </div>
</template>

<script>
export default {
  data() {
    return {
	  mt:1,
	  tk:0,
	  add_id:0,
	  addlist: [],
	  addlist1: [],
	  datalist: [

	  ],
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData:[],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
	  movielist_2: [{},{},{},{}],
	  index:[],
	  x_list:[],
      isLoading: false,
	  show: false,
	  show2: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
		// autoplay:true,
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      movielistSwiperOption2: {
        slidesPerView: 'auto',
		autoplay:true,
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      }
    };
  },
  methods:{
	tapp(index){
		if(index==1){
			  this.mt=1;
			  this.tk=0;		
		}else{
			  this.mt=0;
			  this.tk=1;			
		}
	},
	addlist_id(id){
		this.add_id=id;
		
		this.$http({
			method: 'get',
			url: 'address_class',
			data: { x_id: id }
		}).then(res => {
			this.x_list = res.data;
			console.log(res.data);
			this.show=0;
			this.show2=1;
			
			// if(res.data){
			// 	this.$http({
			// 		method: 'get',
			// 		url: 'xuanfeilist',
			// 		data: { id:id,all:1}
			// 	}).then(res => {
			// 		this.datalist = res.data;
					
			// 	});					
			// }
			
		});		
		
	
		
	},
	addlist_id2(){
		
		this.$toast(this.$t('lxndjdyjhzh'))
		this.show=0;
		this.show2=0;			
		return false;
		// this.$http({
		// 	method: 'get',
		// 	url: 'xuanfeilist',
		// 	data: { id:id}
		// }).then(res => {
		// 	this.movielist_2=[];
		// 	this.movielist_2 = res.data;
		// 	this.show=0;
		// 	this.show2=0;			
		// });					
			
			
	},	
	
	
	getAddress() {
		this.$http({
			method: 'get',
			url: 'address_list'
		}).then(res => {
			this.addlist = res.data;
			console.log(res.data)
		});
	},
    showPopup() {
      this.show = true;
    },
	profile(id) {
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else{
		  this.$router.push({ path: '/profile?id=' + id });
	  }
		
	},
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    toXiaoFei(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/profile?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("刷新成功");
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
		  console.log(res);
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
		this.getMovieList_2(this.basicData);//获取首页视频1
		this.index_c(this.basicData);
		
      })

    },
    getNotice(data){
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getMovieList_2(data){
		console.log(data.movielist_2);
      this.movielist_2 = data.movielist_2
    },
    index_c(data){
		console.log(data.index);
      this.index = data.index
    },
    getBanner(data){
      this.banners = data.banners;
    }
  },
  mounted () {

  },
  created() {
	this.getAddress();
    this.getBasicConfig();
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.van-icon, .van-icon:before {
    display: inline-block;
}
.home-container .cityPopup li:first-child {
    width: 100%;
}

.van-popup {
    background: none;
    width: 70%;
}
.item.tiaokuan ul li p{
    color: #e1be9c;
    background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.733vw;
    font-weight: 600;
    text-align: left;
    padding: 1.333vw 0;
}
.item.tiaokuan ul li span{
    font-size: 3vw;
    color: #fff;
}
.cityPopup li:last-child span{
    padding: .533vw 1.867vw;
    border-radius: 3.2vw;
    color: #8a5623;
    font-size: 3.2vw;
    background: linear-gradient(#fde3ca, #e7b486);
    display: block;
    margin: 0 auto;
    width: 45%;
}
.cityPopup li:last-child {
    margin-top: 1.333vw;
    width: 100%;
}
.item.tiaokuan ul li{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.item ul li:before {
    width: 8vw;
    left: 1%;
}
.item ul li:after {
    width: 13.333vw;
    left: 40%;
}
.item ul li:after, .item ul li:before {
    content: "";
    position: absolute;
    top: -100%;
    height: 400%;
    background: linear-gradient(90deg, hsla(0, 18%, 88%, .1), transparent);
    transform: rotate(50deg);
}
.item ul li{
    float: left;
    width: 100%;
    margin-top: 1.333vw;
    min-height: 1.867vw;
    background: linear-gradient(270deg, #b47d9c, #7e5678);
    position: relative;
    overflow: hidden;
    margin-bottom: 1.333vw;
    border-radius: 1.333vw;
    border: 1px solid #7e6a56;
    padding: 1.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cityPopup{
    width: 88%;
    margin: 0 auto;
    padding: 2.667vw;
    border: 1px solid #7e6a56;
    border-radius: .8vw;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    background: linear-gradient(120deg, #212121, #313131);
    overflow: hidden;
}
ul li .left .ft{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1.333vw;
}
.item ul li .left .ft img{
    width: 5.2vw;
    margin-right: .267vw;
}
.item ul li .left .ft span{
    font-size: 3.6vw;
    color: #fff;
    margin-right: .8vw;
}
.linear-bg{
    display: flex;
    height: 13.333vw;
    background: linear-gradient(270deg, #e6c3a1, #7e5678);
    color: #f3c7a2;
    text-align: center;
    line-height: 13.333vw;
    font-size: 4.667vw;
    width: 100%;
    justify-content: center;
}
::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}
.item ul li i{
    position: absolute;
    right: -2.333vw;
    top: 2.667vw;
    background: #ff6056;
    font-size: 2.133vw;
    padding: .533vw 2.667vw;
    font-style: normal;
    transform: rotate(45deg);
    color: #e7e7e7;
}
.noticeContent{
    padding: 2.667vw;
}
.navs>nav a.active{
    background: linear-gradient(#fde3ca, #e7b486);
    color: #8a5623;
}
.cityPopup li:first-child .cubeic-like{
    font-size: 2.667vw;
}
.cityPopup li{
    width: 33%;
    flex-shrink: 0;
    padding: 1.333vw 0;
    color: #fff;
    word-break: break-all;
}
.cityPopup li:first-child {
    color: #f54c48;
}
.item ul li .left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.item ul li .left p{
    background: linear-gradient(90deg, #efcba7, #dcab76);
    color: #8d5825;
    display: inline-block;
    font-size: 2.6vw;
    font-weight: 600;
    padding: 2.667vw 2.4vw;
    border-radius: 1.333vw;
    margin-bottom: 1.333vw;
    margin-top: 2.133vw;
}
.item ul li .left .ziliao{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 3.733vw;
    margin-left: 1.333vw;
}
.item ul li .left .ziliao span {
    padding: .4vw 0;
    color: #e8e8e8;
}
.navs>nav a{
    font-size: 2.867vw;
    padding: 1.333vw 2vw;
    border-radius: 6.667vw;
    margin-right: 1.867vw;
    background: linear-gradient(90deg, #5b606c, #2b2b2b);
    color: #fff;
    margin-bottom: 1.333vw;
}
.item ul li .right .avatar img{
    width: 100%;
}
.item ul li .right .avatar{
    width: 28vw;
    height: 28vw;
    border-radius: 50%;
    border: .267vw solid #5c534b;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.item ul li .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.item ul li:after, .item ul li:before {
    content: "";
    position: absolute;
    top: -100%;
    height: 400%;
    background: linear-gradient(90deg, hsla(0, 18%, 88%, .1), transparent);
    transform: rotate(50deg);
}
.item ul li:after {
    width: 13.333vw;
    left: 40%;
}
.item ul li:before {
    width: 8vw;
    left: 1%;
}
.item ul li:after, .item ul li:before {
    content: "";
    position: absolute;
    top: -100%;
    height: 400%;
    background: linear-gradient(90deg, hsla(0, 18%, 88%, .1), transparent);
    transform: rotate(50deg);
}
.item ul li{
    float: left;
    width: 100%;
    margin-top: 1.333vw;
    min-height: 1.867vw;
    background: linear-gradient(270deg, #b47d9c, #7e5678);
    position: relative;
    overflow: hidden;
    margin-bottom: 1.333vw;
    border-radius: 1.333vw;
    border: 1px solid #7e6a56;
    padding: 1.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sm {
    width: 100%;
    min-height: 1rem;
    border-radius: 1.333vw;
    border: 1px solid #766350;
    margin: 1.333vw 0;
    background: linear-gradient(270deg, #b47d9c, #7e5678);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.333vw;
    position: relative;
    overflow: hidden;
}
.sm>div{
	position: relative;
	z-index: 99;
	display: flex;
}
.sm:before {
    left: -4%;
}
.sm:after {
    left: -35%;
    z-index: 0;
}
*, :after, :before {
    box-sizing: border-box;
}
.sm:after, .sm:before {
    content: "";
    position: absolute;
    width: 137.4vw;
    height: 19.2vw;
    background: linear-gradient(0deg, rgba(222, 213, 162, .29), transparent);
    transform: rotate(139deg);
}
.sm:after, .sm:before {
    content: "";
    position: absolute;
    width: 137.4vw;
    height: 19.2vw;
    background: linear-gradient(0deg, rgba(222, 213, 162, .29), transparent);
    transform: rotate(139deg);
}
.sm .left{
    width: 32%;
    position: relative;
    z-index: 99;
}
.sm .left:before {
    content: "";
    position: absolute;
    width: .02rem;
    height: 100%;
    background: #f6d5a1;
    right: 0;
    top: 0;
}
.sm .left p{
    font-weight: 1000;
    background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 4.267vw;
    text-align: center;
    margin-top: 3vw;
}
.sm .right{
    flex: 1;
    padding-left: 1.333vw;
}
.sm .right p{
    color: white;
    font-size: 5vw;
    line-height: 6.4vw;
}
.sm .left span, .sm>span {
    color: #ddb285;
    font-size: 3vw;
    padding-top: 1.333vw;
    text-align: center;
}
.sm>span{
    line-height: 5.067vw;
}
.sm:after {
    left: -35%;
    z-index: 0;
}
.sm:after, .sm:before {
    content: "";
    position: absolute;
    width: 137.4vw;
    height: 19.2vw;
    background: linear-gradient(0deg, rgba(222, 213, 162, .29), transparent);
    transform: rotate(139deg);
}


.xiaofei{

::v-deep  .swiper-wrapper{
	width:43%;
}
::v-deep .van-image--round img {
    border-radius: inherit;
    object-fit: cover;
}	
}
.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  /* background-color: #ffffff; */
  background-color: #d0abc0!important;
  
  
  height: 100%;
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}
::v-deep .van-notice-bar__left-icon {
	font-size: 4vw;
}
::v-deep .van-pull-refresh{
	background-color: #d0abc0 !important;
	padding-bottom: 100px;
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:40px;
}
.notice-swipe{
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner{
  width: 100%;
  
  /* margin-top: -23%; */
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    /* background-color: #ffffff; */
	background-color: #d0abc0!important;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height:  100%;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div> div:first-child{
  width: 430px;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span{
  font-size: 20px;
  color: #2b333f;
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
}

.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #fff;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 25px;
  color: #2b333f;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}
.hot-recommend{
  width: 100%;
  flex: 1;
  background-color: #d0abc0!important;
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0{
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:550px;
  height:330px;
}
.movie_cover1{
  border-radius: 10px;
  width:90%;
  /* text-align: center; */
  /* margin: 0 auto; */
  height:300px;
  
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span{
  font-size: 30px;
}
.movie_list_1{
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img{
  border-radius: 10px;
  width:335px;
  height:290px;
}
.home-scroll{
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item{
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;

}
.movie_list_1 .movie-list-item:nth-child(odd){
  margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right:110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #2b333f;
  font-size: 30px;
}
.hot-items-div .game_item_img{
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}
</style>
