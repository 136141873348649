<template>
	<div class="page">
    <iframe
      ref="iframeModel"
      :src="url"
      style="width: 100vw; height: 92vh"    
      frameborder="0"
    >
    </iframe>  
	</div>
</template>

<script>
export default {
  data() {
    return {
		url:'',
		userInfo:{
		},
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
			this.url="https://im.koreameets.com/#/pages/in/login?name="+res.data.id
			// this.url="http://localhost:8081/#/pages/in/login?name="+res.data.username
			
			
			
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast(this.$t("video.account_out"));
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })

    },
    toServicePage(){
      const service = this.$store.getters.getBaseInfo;
      console.log(service)
      if (service.iskefu == 1){
        console.log('ssss')
        window.location.href = service.kefu
      }
      // this.$router.push("ServicePage");
    }
  },
  created() {
    if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
    }
	  
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }
	
  }
};
</script>
<style>


	
	.page {
		height:100%;
			background-color: rgb(208, 171, 192) !important;
	}


</style>
