<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#7e5678"
    :border="true"
    inactive-color="#ffffff"
  >
  <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <span>{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/1/homec.jpg' : '/img/footer/1/home.jpg'"
          :alt="$t('foorter.index')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? '/img/footer/1/homec.jpg' : '/img/footer/1/home.jpg'"
          :alt="$t('foorter.index')"
          :class="$t('foorter.index')"
          style="height: 4rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    <!-- 预约 -->

	
	
    <!-- 中间 -->
<!--    <van-tabbar-item to="/Choose">
      <span></span>
      <template #icon="props">
        <img class="tui" 
          :src="props.active ? '/img/footer/beauty.52660ad11.png' : '/img/footer/beauty.52660ad11.png'"
        />
      </template>
    </van-tabbar-item> -->
    <!-- 视频 -->
    <van-tabbar-item to="/Video">
      <span>{{ $t("foorter.video") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/1/videoc.jpg' : '/img/footer/1/video.jpg'"
          :alt="$t('foorter.video')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? '/img/footer/1/videoc.jpg' : '/img/footer/1/video.jpg'"
          :alt="$t('foorter.video')"
          :class="$t('foorter.video')"
          style="height: 4rem"
          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
   <!-- Game -->
	<van-tabbar-item to="/Lottery?key=game1&id=10">
      <span>{{ $t("foorter.subscribe") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/subscribed.jpg' : '/img/footer/subscribe.jpg'"
          :alt="$t('foorter.subscribe')"
          v-show="show !== 2"
        />
        <img
          :src="props.active ? '/img/footer/subscribed.jpg' : '/img/footer/subscribe.jpg'"
          :alt="$t('foorter.subscribe')"
          :class="$t('foorter.subscribe')"
          style="height: 4rem"
          v-show="show === 2"
        />
      </template>
    </van-tabbar-item>
	

    <!-- 消息 -->
    <van-tabbar-item to="/ServiceOnline2"  :badge="num" v-if="is_num">

      <span>{{ $t("foorter.xiaoxi") }}</span>
      <template #icon="props">
	
        <img
          :src="props.active ? '/img/footer/1/videoc.jpg' : '/img/footer/1/video.jpg'"
          :alt="$t('foorter.xiaoxi')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? '/img/footer/1/videoc.jpg' : '/img/footer/1/video.jpg'"
          :alt="$t('foorter.xiaoxi')"
          :class="$t('foorter.xiaoxi')"
          style="height: 4rem"
          v-show="show === 3"
        />

      </template>
	  
	  
	  
    </van-tabbar-item>
    <van-tabbar-item to="/ServiceOnline2" v-else>

      <span>{{ $t("foorter.xiaoxi") }}</span>
      <template #icon="props">
	
        <img
          :src="props.active ? '/img/footer/1/videoc.jpg' : '/img/footer/1/video.jpg'"
          :alt="$t('foorter.xiaoxi')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? '/img/footer/1/videoc.jpg' : '/img/footer/1/video.jpg'"
          :alt="$t('foorter.xiaoxi')"
          :class="$t('foorter.xiaoxi')"
          style="height: 4rem"
          v-show="show === 3"
        />

      </template>
	  
	  
	  
    </van-tabbar-item>	
	
	
	

	
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span>{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/1/myc.jpg' : '/img/footer/1/my.jpg'"
          :alt="$t('foorter.my')"
          v-show="show !== 4"
        />
        <img
          :src="props.active ? '/img/footer/1/myc.jpg' : '/img/footer/1/my.jpg'"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
          style="height: 4rem"
          v-show="show === 4"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0,
	  num:0,
	  is_num:false
    };
  },
  methods: {
    getUserInfo(){
	
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
		  
        if(res.code == 200){
			  this.$http({
				method: 'post',
				data:{
				  username:res.data.username,
				},
				url: 'wd'
			  }).then(res1=>{
				  console.log(res1);
				  this.num=res1.data;
				  if(res1.data>0){
					  this.is_num=true;
				  }else{
					  this.is_num=false;
				  }
			  })


        }
      })
	  
		  


	  
	  
	  
    }  
	  
  },
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 2;
        this.show = true;
      }else if (to.name == "video") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "ServiceOnline2") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      }else {
        this.show = false;
      }
    },
  },
  mounted(){
    if(localStorage.getItem('token')){
		var that=this;
		setInterval(function(){
			that.getUserInfo();
		},2000);
		
    }	  
  },
  created() {
    if(localStorage.getItem('token')){
		this.getUserInfo();
    }
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "video") {
      this.active = 1;
      this.show = true;
    }else if (this.$route.name  == "ServiceOnline2") {
        this.active = 3;
        this.show = true;
      }  else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 60px;
.van-tabbar {
  height: @tabbar-height;
  background-color: #b47d9c;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
    color: #f9f7f6!important;
}
.van-tabbar-item--active {
    background-color: #7e5678;
}

.tui {
  width: 4rem;
  height: 4rem!important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  // border: 10px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
::v-deep  .van-info{
	width: 4.333vw;
    font-size: 3.0vw;
}
</style>
